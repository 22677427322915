import React, { Component } from "react";
import AboutWrapper from "./AboutWrapper";
import Breadcrumb from "./Breadcrumb";


class About extends Component {
  render() {
    return (
      <>
        {/* all about section componet */}
        <Breadcrumb />
        <AboutWrapper />
      </>
    );
  }
}

export default About;
