
import p1 from "../../../assets/images/new/p1.jpeg"
import p2 from "../../../assets/images/new/p2.jpeg"
import p3 from "../../../assets/images/new/p3.jpeg"


export const PopularEventData = [
    {
        id: 1,
        title: "ÉVÉNEMENT, ZIARA 2023",
        date: "21 Janvier 2023",
        location: "Sousse, Tunisie",
        image: p1,
    },
    {
        id: 2,
        title: "Les entreprises partagent des stratégies pour capturer des audiences sur mobile.",
        date: "24 Janvier 2020",
        location: "Tunis, Tunisie",
        image: p2,
    },
    {
        id: 3,
        title: "Les entreprises partagent des stratégies pour capturer des audiences sur mobile.",
        date: "21 Novembre 2021",
        location: "Tunis, Tunisie",
        image: p3,
    },
 
    
    // Add more event data objects as needed
];