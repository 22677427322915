// EventCard.js

import React, { Component } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../../assets/css/animate.css";
class EventCard extends Component {

  componentDidMount() {
    new WOW.WOW().init()
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const { event } = this.props;
    return (
      // 
      <div className="wow d-flex justify-content-around  col-lg-4 col-md-6 col-sm-12fadeInUp animated " >
        <div className="event-card-md" >
          <div className="event-thumb" > 
            <img  src={event.image} alt="Imgs"/> 
          </div>
          <div className="event-content">
            <div className="event-info">
              <div className="event-date">
                <Link onClick={this.scrollTop} to="#">
                  <i className="bi bi-calendar2-week" /> {event.date}
                </Link>
              </div>
              <div className="event-location">
                <Link onClick={this.scrollTop} to="#">
                  <i className="bi bi-geo-alt" /> {event.location}
                </Link>
              </div>
            </div>
            {/* <h5 className="event-title">
              <Link onClick={this.scrollTop} >
                {event.title}
              </Link>
            </h5> */}
          </div>
        </div>
      </div>
    );
  }
}

export default EventCard;
