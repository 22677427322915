
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import WOW from "wowjs";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import "../../../assets/css/animate.css";
import HeroIMG1 from "../../../assets/images/new/p4.jpeg";
import HeroIMG2 from "../../../assets/images/shapes/hero-animi.png";
import HeroIMG3 from "../../../assets/images/hero/musicEvent.jpeg";
import ImageShape from "../../../assets/images/shapes/hero-shape.png";

const sliderImages = [HeroIMG1, HeroIMG3];

// install Swiper modules
SwiperCore.use([Navigation]);

const HeroArea = () => {
  const [backgroundImage, setBackgroundImage] = useState(sliderImages[0]);

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const handleSlideChange = (swiper) => {
    setBackgroundImage(sliderImages[swiper.realIndex]);
  };

  const sliderInit = {
    slidesPerView: 1,
    loop: true,
    speed: 5000,
    effect: "slide",
    autoplay: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".hero-pagination",
      type: "custom",
      clickable: true,
      renderCustom: function (swiper, current, total) {
        const numberAppend = (d) =>
          d < 10 ? "0" + d.toString() : d.toString();
        return numberAppend(current);
      },
    },
    onSlideChange: handleSlideChange,
  };

  return (
    <div className="main-slider-wrapper">
      <div
        className="hero-area"
        style={{
          background:` linear-gradient(rgba(23, 42, 0, 0.56), rgba(23, 42, 0, 0.56)), url(${backgroundImage}) no-repeat bottom / cover`,
        }}
      >
        <div className="hero-shape">
          <img src={ImageShape} alt="HeroIMG" />
        </div>
        <div className="container">
          <div className="swiper-container hero-slider overflow-hidden">
            <Swiper {...sliderInit} className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="slide-content">
                      <h5>
                        <i className="bi bi-calendar2-week" />
                        Août 15 - 17, 2023
                      </h5>
                      <h2>
                        ÉVÉNEMENT, <span>HADHRA</span>
                      </h2>
                      <ul className="featured-event">
                   
                        <li>
                          <i className="bi bi-pin-map" /> Tunis, Tunisie
                        </li>
                      </ul>
                      <div className="slider-btns">
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                          to={`${process.env.PUBLIC_URL}/event`}
                          className="primary-btn-outline"
                        >
                          VOIR PLUS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 text-center">
                    <div className="slide-figure position-relative d-lg-flex justify-content-center">
                      <div className="animated-shape">
                        <img src={HeroIMG2} alt="HeroIMG2" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="slide-content">
                      <h5>
                        <i className="bi bi-calendar2-week" /> Juin 28, 2023
                      </h5>
                      <h2>
                        SOIRÉE LIVE <span>BAND</span>
                      </h2>
                      <ul className="featured-event">
                       
                        <li>
                          <i className="bi bi-pin-map" /> Sousse, Tunis
                        </li>
                      </ul>
                      <div className="slider-btns">
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                          to={`${process.env.PUBLIC_URL}/event`}
                          className="primary-btn-outline"
                        >
                          VOIR PLUS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 text-center">
                    <div className="slide-figure position-relative d-lg-flex justify-content-center">
                      <div className="animated-shape">
                        <img src={HeroIMG2} alt="HeroIMG2" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="slider-arrows text-center d-lg-block d-none">
          <div
            className="swiper-button-next"
            tabIndex={0}
            role="button"
            aria-label="Next slide"
          >
            <i className="bi bi-chevron-up" />
          </div>
          <div className="hero-pagination d-block w-auto" />
          <div
            className="swiper-button-prev"
            tabIndex={0}
            role="button"
            aria-label="Previous slide"
          >
            <i className="bi bi-chevron-down" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroArea;
