import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logoblanc from "../../assets/images/new/32blanc.png";


class HomeOneFooter extends Component {
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        {/* ===============  footer area start  =============== */}
        <div className="footer-outer pt-120 ">
          <div className="footer-area">
            <div className="container">
              <div className="footer-wrapper">
                <div className="row" style={{"gap":"6%"}}>

                  <div className="col-lg-4 order-lg-1 order-3 " style={{marginRight:"25px"}}>
                  <Link
                      onClick={this.scrollTop}
                      to={`${process.env.PUBLIC_URL}/`}
                      className="logo-white"
                    >
                      {/* <img src={Logoblanc} alt="logo" height={50} width={150} style={{marginLeft:'20px'}}/> */}
                    </Link>
                    <div className="footer-newslatter-wrapper text-center">
                      <h3>Éclairez vos événements avec éclat </h3>
                      <h5>Sonorisez-les avec perfection</h5>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-5 order-2">
                    <div className="footer-widget mt-0">
                      <h5 className="footer-widget-title">Liens rapides</h5>
                      <div className="footer-links">
                        <ul className="link-list">
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/`}
                              className="footer-link"
                            >
                              Acceuil
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/about`}
                              className="footer-link"
                            >
                              À Propos De Nous
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/event`}
                              className="footer-link"
                            >
                              Événements
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/gallery`}
                              className="footer-link"
                            >
                              Galerie
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/contact`}
                              className="footer-link"
                            >
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-7 order-lg-3 order-2 ">
                    <div className="footer-widget">
                      {/* <h5 className="footer-widget-title">Contact</h5> */}
                      <div className="footer-links">
                        <ul className="link-list">
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-telephone-plus" style={{ color: '#6cc071' }}/>
                            </div>
                            <div className="contact-links">
                              <Link onClick={this.scrollTop} to={"#"}>
                                +216 51 970 077
                              </Link>
                              <Link onClick={this.scrollTop} to={"#"}>
                                +216 24 616 084
                              </Link>
                              <Link onClick={this.scrollTop} to={"#"}>
                                +216 58 111 158
                              </Link>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-envelope-open" style={{ color: '#6cc071' }}/>
                            </div>
                            <div className="contact-links">
                              <Link
                                onClick={this.scrollTop}
                              >
                                sls.sound.direction@gmail.com
                              </Link>
                              <Link onClick={this.scrollTop} >
                                sls.sound.commercial@gmail.com
                              </Link>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-geo-alt" style={{ color: '#6cc071' }}/>
                            </div>
                            <div className="contact-links">
                              <Link onClick={this.scrollTop} to={"#"}>
                                Rue salem bchir , 5000 Monastir
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="row align-items-center">
                  <div className="col-lg-5  order-3 order-lg-1">
                    <div className="footer-copyright">
                      <p>
                        Copyright 2024 SLS | {" "}
                        <Link onClick={this.scrollTop} to={"#"}>
                          Sound Light Systems
                        </Link>
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-md-6 order-1 order-lg-2">
                    <div className="footer-logo">
                      <Link onClick={this.scrollTop} to={"#"}>
                        <img src="assets/images/logo-v2.png" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 order-2 order-lg-3">
                    <ul className="d-flex footer-social-links justify-content-lg-end justify-content-center">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-whatsapp" />
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  footer area end  =============== */}
      </>
    );
  }
}

export default HomeOneFooter;
