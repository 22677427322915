import React, { Component } from "react";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import EventCard from "../events/component/eventCard";
import { PopularEventData } from "../events/PopularEventData";
class PopularEventArea extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        {/* ===============  Event Area start  =============== */}
        <div className="event-area">
          <div className="container position-relative pt-110">
            <div className="row  ">
                <div className="section-head">
                  <h3>Nos événements</h3>
                </div>
         
            </div>
              <div className="all-event-cards" >
                <div className="tab-content" id="pills-tabContent" >
                  <div
                    className="tab-pane fade show active"
                    id="pills-event1"
                    role="tabpanel"
                    aria-labelledby="pills-tab1"
                  
                  >
                    <div className="row" >
                      {PopularEventData.map((event, index) => (
                        <EventCard key={index} event={event} />
                      ))}
                    </div>
                  </div>
                  {/* Additional tab-pane content */}
                </div>
              </div>
            </div>
        </div>
        {/* ===============  Event Area end  =============== */}
      </>
    );
  }
}

export default PopularEventArea;
