// eventData.js
// image import
import p1 from "../../../assets/images/new/p1.jpeg"
import p2 from "../../../assets/images/new/p2.jpeg"
import p3 from "../../../assets/images/new/p3.jpeg"
import p4 from "../../../assets/images/new/p4.jpeg"
import p5 from "../../../assets/images/hero/musicEvent.jpeg"
import p6 from "../../../assets/images/hero/event1.jpeg"

const eventData = [
    {
        id: 1,
        title: "ÉVÉNEMENT, ZIARA 2023",
        date: "21 Janvier 2023",
        location: "Sousse, Tunisie",
        image: p1,
    },
    {
        id: 2,
        title: "Les entreprises partagent des stratégies pour capturer des audiences sur mobile.",
        date: "24 Janvier 2020",
        location: "Tunis, Tunisie",
        image: p2,
    },
    {
        id: 3,
        title: "Les entreprises partagent des stratégies pour capturer des audiences sur mobile.",
        date: "21 Novembre 2021",
        location: "Tunis, Tunisie",
        image: p3,
    },
    {
        id: 4,
        title: "ÉVÉNEMENT, HADHRA 2023",
        date: "15 - 17 Août 2020",
        location: "Tunis, Tunisie",
        image: p4,
    },
    {
        id: 5,
        title: "SOIRÉE LIVE BAND",
        date: "28 Juin 2024",
        location: "Sousse, Tunisie",
        image: p5,
    },
    {
        id: 6,
        title: "ÉVÉNEMENT D'ASSOCIATION",
        date: "02 Mai 2023",
        location: "Sousse, Tunisie",
        image: p6,
    },
    // Add more event data objects as needed
];

export default eventData;
