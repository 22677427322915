import React, { Component } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import Modal from "react-modal"; // Import Modal from react-modal

// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// icon image
import Icon2 from "../../../assets/images/icons/event-sm.png";
import QuoteIcon from "../../../assets/images/icons/qoute-icon.png";
import Icon3 from "../../../assets/images/icons/sed-sm.png";
import Icon1 from "../../../assets/images/icons/speaker-sm.png";
// feature icon image import
import FeatureIcon3 from "../../../assets/images/icons/gaust-md.png";
import FeatureIcon2 from "../../../assets/images/icons/sound-md.png";
import FeatureIcon1 from "../../../assets/images/icons/speaker-md.png";
import VideoShapeIcon from "../../../assets/images/shapes/play-btn.png";
import p1 from "../../../assets/images/new/p1.jpeg"
import p15 from "../../../assets/images/new/p15.jpeg"
import p18 from "../../../assets/images/new/p18.jpeg"
import VideoFile from "../../../assets/images/new/v1.mov"; // Importez le fichier vidéo


class AboutWrapper extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    // animation script
    new WOW.WOW().init();
  }
  openModal = () => {
    this.setState({ isOpen: true });
  };

  // Function to handle closing the modal
  closeModal = () => {
    this.setState({ isOpen: false });
  };
  render() {
    // modal video change state
    const { isOpen } = this.state;
    return (
      <>
        {/* =============== About wrapper start =============== */}
        <div className="about-wrapper mt-96">
          <div className="container">
            <div className="about-company">
              <div className="row">
                <div className="col-lg-6">
                  <div className="company-info">
                    <h5>À propos Sound Light Systems</h5>
                    <h2>
                      Que vous soyez un DJ professionnel, un organisateur
                      d'événements ou un passionné de sonorisation,
                    </h2>
                    <p>
                      Bienvenue chez SLS , votre destination de confiance pour
                      l'achat d'équipements de sonorisation et d'éclairage de
                      qualité supérieure. Depuis notre création, nous nous
                      engageons à fournir à nos clients les meilleurs produits
                      et services pour répondre à leurs besoins audiovisuels.
                    </p>
                    <div className="row">
                      <div className="col-sm-4 col-6">
                        <div className="about-infobox">
                          <div className="info-icon">
                            <img src={Icon1} alt="Imgs" />
                          </div>
                          <div className="info-content">
                            <h3>
                              <CountUp
                                start={0}
                                end={40}
                                duration={4.75}
                              ></CountUp>
                              +
                            </h3>
                            <h6>Equipement</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 col-6">
                        <div className="about-infobox">
                          <div className="info-icon">
                            <img src={Icon2} alt="Imgs" />
                          </div>
                          <div className="info-content">
                            <h3>
                              <CountUp
                                start={0}
                                end={100}
                                duration={3.75}
                              ></CountUp>
                              +
                            </h3>
                            <h6>Événement</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 col-6">
                        <div className="about-infobox">
                          <div className="info-icon">
                            <img src={Icon3} alt="Imgs" />
                          </div>
                          <div className="info-content">
                            <h3>
                              <CountUp
                                start={0}
                                end={30}
                                duration={4.75}
                              ></CountUp>
                              +
                            </h3>
                            <h6>Retour positif</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-more-btn">
                      <Link
                        onClick={this.scrollTop}
                        to={"/contact"}
                        className="primary-btn-fill"
                      >
                        Contacter nous
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="company-mini-gallary">
                    <div className="Gallary-item1 Gallary-item">
                      <img
                        src={p1} width="260"
                        height="146"
                        alt="Imgs"
                        className="img-fluid"
                      />
                    </div>
                    <div className="Gallary-item2 Gallary-item">
                      <img
                        src={p15}
                        width="260"
                        height="146"
                        alt="Imgs"
                        className="img-fluid"
                      />
                    </div>
                    <div className="Gallary-item3 Gallary-item Gallary-video">
                      <img
                        src={p18}
                        alt="abput vedio"
                        className="img-fluid"
                      />
                      <div className="video-icon">
                        <i onClick={this.openModal} className="video-popup">
                          <img src={VideoShapeIcon} alt="Imgs" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ModalVideo component for playing video */}
                <Modal
                  isOpen={isOpen}
                  onRequestClose={this.closeModal}
                  style={{
                    overlay: {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '100%',
                      height: '100%',
                      padding: '0',
                      border: 'none',
                      background: 'transparent',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center' 
                    }
                  }}
                >
                  <video controls >
                    <source src={VideoFile} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Modal>
              </div>
            </div>
            <div className="company-feature mt-96">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon1} alt="Imgs" />
                        </div>
                        <h5>Service Clientèle de Premier Ordre</h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon2} alt="Imgs" />
                        </div>
                        <h5>Une Large Sélection de Divers Produits</h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon3} alt="Imgs" />
                        </div>
                        <h5>Qualité Exceptionnelle</h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon1} alt="Imgs" />
                        </div>
                        <h5>Prix Compétitifs</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-info">
                    <h5>Pourquoi Nous Choisir ?</h5>
                    <p>
                      Chez SLS, nous comprenons l'importance
                      cruciale d'avoir les bons équipements de sonorisation et
                      d'éclairage pour réussir votre événement ou projet. Notre
                      engagement envers la qualité, la variété des produits, le
                      service clientèle exceptionnel et des prix compétitifs
                      font de nous le choix évident pour tous vos besoins
                      audiovisuels. Nous ne faisons aucun compromis sur la
                      qualité de nos produits, nous offrons une vaste sélection
                      pour répondre à toutes les exigences, notre équipe dévouée
                      est toujours là pour vous guider et nous nous efforçons de
                      rendre nos produits accessibles à tous les budgets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-history position-relative mt-120">
              <h3 className="float-title position-absolute">
                Histoire de Sound Light Systems
              </h3>
              <div className="qoute-icon position-absolute">
                <img src={QuoteIcon} alt="Imgs" />
              </div>
              <p>
                Depuis nos débuts modestes, SLS a évolué pour
                devenir un leader reconnu dans le secteur de la vente
                d'équipements de sonorisation et d'éclairage. Fondée par Khalil Tamboura , notre entreprise a su gagner
                la confiance de clients fidèles grâce à notre engagement
                indéfectible envers la qualité, le service clientèle et
                l'innovation.
              </p>
              <p>
                Au fil des ans, nous avons consolidé notre réputation en
                travaillant avec certains des plus grands noms de l'industrie et
                en fournissant des solutions audiovisuelles de pointe pour une
                multitude d'événements, des concerts en plein air aux mariages
                élégants.
              </p>
              <p>
                Aujourd'hui, notre passion pour l'excellence continue de nous
                guider dans tout ce que nous faisons. Nous sommes fiers de notre
                parcours et nous nous engageons à poursuivre notre mission
                d'offrir à nos clients des produits et services de la plus haute
                qualité.
              </p>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default AboutWrapper;
