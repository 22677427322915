import React, { Component } from "react";
// Swiper slider imported
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination
} from "swiper";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
// import image
import QuoteIcon from "../../../assets/images/icons/qoute-icon.png";
import TestimonialShape from "../../../assets/images/shapes/testi-shape.png";
import Reviewer from "../../../assets/images/speaker/reviewer.png";
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);
class Testimonial extends Component {
  render() {
    const sliderInit = {
      slidesPerView: 1,
      loop: true,
      speed: 2400,
      effect: "slide",
      nav: true,
      autoplay: true,
      pagination: {
        el: ".swiper-pagination",
        type: "custom",
        clickable: true,
        renderCustom: function (swiper, current, total) {
          function numberAppend(d) {
            return d < 10 ? "0" + d.toString() : d.toString();
          }
          return numberAppend(current) + `<span></span>` + numberAppend(total);
        },
      },
      navigation: {
        nextEl: ".testi-button-next",
        prevEl: ".testi-button-prev",
      },
    };
    return (
      <>
        {/* ===============  testimonial area start  =============== */}
        <div className="testimonial-area">
          <div className="container position-relative">
            <div className="testimonial-wrapper position-relative">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="testimonial-info">
                    
                    <h3>Ce que nos clients disent de nos services</h3>
                    <p>
                      Découvrez ce que nos clients satisfaits ont à dire sur leur expérience avec notre agence de location d'équipements de sonorisation et d'éclairage. Chez SLS, nous nous efforçons de fournir des services de haute qualité pour tous types d'événements.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="testimonial-slider-wrapper">
                    <div className="swiper testimonial-slider">
                      <Swiper {...sliderInit} className="swiper-wrapper">
                        <SwiperSlide className="swiper-slide">
                          <div className="testimonial-card">
                            <div className="testimonial-shape">
                              <img src={TestimonialShape} alt="Imgs" />
                            </div>
                            <div className="testimonial-top">
                              {/* <div className="reviewer-image">
                                <img src={Reviewer} alt="Imgs" />
                              </div> */}
                              <div className="reviewer-info">
                                <div className="quote-icon d-md-block d-none">
                                  <img src={QuoteIcon} alt="Imgs" />
                                </div>
                                <h3 className="reviewer-name">
                                  Marouen nouira
                                </h3>
                                <p>Marketing</p>
                              </div>
                            </div>
                            <div className="review-texts">
                              <p>
                              J'ai utilisé les services de location d'équipements audio et d'éclairage de SLS pour mon événement et j'ai été très satisfait de la qualité du matériel et du professionnalisme de l'équipe.  Leur équipe compétente et leur flexibilité ont été essentielles pour répondre à mes besoins spécifiques. Je les recommande vivement.

                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                          <div className="testimonial-card">
                            <div className="testimonial-shape">
                              <img src={TestimonialShape} alt="Imgs" />
                            </div>
                            <div className="testimonial-top">
                              {/* <div className="reviewer-image">
                                <img src={Reviewer} alt="Imgs" />
                              </div> */}
                              <div className="reviewer-info">
                                <div className="quote-icon d-md-block d-none">
                                  <img src={QuoteIcon} alt="Imgs" />
                                </div>
                                <h3 className="reviewer-name">
                                  Khaled souissi
                                </h3>
                                <p>Responsable de la logistique</p>
                              </div>
                            </div>
                            <div className="review-texts">
                              <p>
                              En tant que responsable de la logistique événementielle, j'ai fait appel à SLS à plusieurs reprises pour la location d'équipements audio et d'éclairage pour nos grands événements d'entreprise. Leur équipe a toujours été réactive, professionnelle et les équipements étaient de haute qualité, ce qui a contribué au succès de nos événements. Je les recommande vivement.
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                          <div className="testimonial-card">
                            <div className="testimonial-shape">
                              <img src={TestimonialShape} alt="Imgs" />
                            </div>
                            <div className="testimonial-top">
                              {/* <div className="reviewer-image">
                                <img src={Reviewer} alt="Imgs" />
                              </div> */}
                              <div className="reviewer-info">
                                <div className="quote-icon d-md-block d-none">
                                  <img src={QuoteIcon} alt="Imgs" />
                                </div>
                                <h3 className="reviewer-name">
                                  Maha Mouelhi
                                </h3>
                                <p>Organisatrice d'événements privés</p>
                              </div>
                            </div>
                            <div className="review-texts">
                              <p>
                              En tant qu'organisatrice d'événements privés, je recherche toujours des partenaires fiables pour m'aider à créer des expériences mémorables pour mes clients. SLS a été ma première option pour la location d'équipements audio et d'éclairage depuis des années. Je suis très satisfaite de leur service et je les recommande sans hésitation
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div className="swiper-pagination" />
                    <div className="testi-arrows text-center d-lg-flex d-none">
                      <div
                        className="testi-button-prev"
                        tabIndex={0}
                        role="button"
                        aria-label="Previous slide">
                        <i className="bi bi-chevron-left" />
                      </div>
                      <div
                        className="testi-button-next"
                        tabIndex={0}
                        role="button"
                        aria-label="Next slide">
                        <i className="bi bi-chevron-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  testimonial area end  =============== */}
      </>
    );
  }
}

export default Testimonial;
