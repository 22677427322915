import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
// Swiper slider imported

import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
// import Gallary7 from "../../../assets/images/gallary/g-l1.png";
// gallary image
// import Gallary6 from "../../../assets/images/gallary/g-l2.png";
// import Gallary3 from "../../../assets/images/gallary/g-l3.png";
// import Gallary4 from "../../../assets/images/gallary/g-sm1.png";
// import Gallary5 from "../../../assets/images/gallary/g-sm2.png";
// import Gallary1 from "../../../assets/images/gallary/g-xl1.png";
// import Gallary2 from "../../../assets/images/gallary/g-xl2.png";
//
import p1 from "../../../assets/images/new/p1.jpeg"
// import p2 from "../../../assets/images/new/p2.jpeg"
import p3 from "../../../assets/images/new/p3.jpeg"
import p4 from "../../../assets/images/new/p4.jpeg"
import p5 from "../../../assets/images/new/p5.jpeg"
import p6 from "../../../assets/images/new/p6.jpeg"
import p7 from "../../../assets/images/new/p7.jpeg"
import p8 from "../../../assets/images/new/p8.jpeg"
//import p9 from "../../../assets/images/new/p9.jpeg"
import p10 from "../../../assets/images/new/p10.jpeg"
import p11 from "../../../assets/images/new/p11.jpeg"
import p12 from "../../../assets/images/new/p12.jpeg"
import p13 from "../../../assets/images/new/p13.jpeg"
import p14 from "../../../assets/images/new/p14.jpeg"
import p15 from "../../../assets/images/new/p15.jpeg"
 import p16 from "../../../assets/images/hero/event1.jpeg"
// import p17 from "../../../assets/images/new/p17.jpeg"
import GalleryItem from "./component/galleryItem";
class Gallary extends Component {
  render() {
    const gallayoneSlide = {
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 24,
      loop: true,
      centeredSlides: true,
      roundLengths: true,
      autoplay: {
        delay: 12000,
      },
      navigation: {
        nextEl: ".gallary-next1",
        prevEl: ".gallary-prev1",
      },

      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    };
    const gallayTwosilde = {
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 24,
      loop: true,
      centeredSlides: true,
      roundLengths: true,
      autoplay: {
        delay: 12000,
      },
      navigation: {
        nextEl: ".gallary-next2",
        prevEl: ".gallary-prev2",
      },
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    };
    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                 
                  <h2 className="page-title">Galerie</h2>
                  <ul className="page-switcher">
                    <li>
                      <Link to={"/"}>
                        Acceuil <i className="bi bi-caret-right" />
                      </Link>
                    </li>
                    <li>Galerie</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        {/* ===============  gallary wrapper start =============== */}
        <div className="gallary-wrapper">
          <div className="container pt-110 position-relative">
            <div className="row">
              <div className="col-lg-12">
              
                <div className="section-head">
                  <h3>Galerie</h3>
                </div>
              </div>
            </div>
            <div className="gallary-group">
              <div className="gallary-group-header">
                <h4 className="gallary-group-title">Éclairez la Scène, Enflammez les Sens</h4>
                <div className="gallary-arrows text-center d-lg-block d-none">
                  <div
                    className="gallary-button-prev gallary-prev1"
                    tabIndex={0}
                    role="button"
                    aria-label="Previous slide"
                  >
                    <i className="bi bi-chevron-left" />
                  </div>
                  <div
                    className="gallary-button-next gallary-next1"
                    tabIndex={0}
                    role="button"
                    aria-label="Next slide"
                  >
                    <i className="bi bi-chevron-right" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="swiper gallary-slider">
                  <SRLWrapper>
                    <Swiper className="swiper-wrapper" {...gallayoneSlide}>
                      <SwiperSlide className="swiper-slide">

                        <GalleryItem src={p1} alt="GallaryIMAGE" />
                        <GalleryItem src={p7} alt="GallaryIMAGE" />
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <GalleryItem src={p4} alt="GallaryIMAGE" />
                        <GalleryItem src={p10} alt="GallaryIMAGE" />

                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <GalleryItem src={p6} alt="GallaryIMAGE" />

                      </SwiperSlide>
                    </Swiper>
                  </SRLWrapper>
                </div>
              </div>
            </div>
            <div className="gallary-group mt-110">
              <div className="gallary-group-header">
                <h4 className="gallary-group-title">Louez la Magie du Son et de la Lumière</h4>
                <div className="gallary-arrows text-center d-lg-block d-none">
                  <div
                    className="gallary-button-prev gallary-prev2 "
                    tabIndex={0}
                    role="button"
                    aria-label="Previous slide"
                  >
                    <i className="bi bi-chevron-left" />
                  </div>
                  <div
                    className="gallary-button-next gallary-next2"
                    tabIndex={0}
                    role="button"
                    aria-label="Next slide"
                  >
                    <i className="bi bi-chevron-right" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="swiper gallary-slider2">
                  <SRLWrapper>
                    <Swiper className="swiper-wrapper" {...gallayTwosilde}>
                      <SwiperSlide className="swiper-slide">
                        <GalleryItem src={p3} alt="GallaryIMAGE" />
                        <GalleryItem src={p5} alt="GallaryIMAGE" />
                        <GalleryItem src={p16} alt="GallaryIMAGE" />
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <GalleryItem src={p8} alt="GallaryIMAGE" />
                        <GalleryItem src={p12} alt="GallaryIMAGE" />
                        <GalleryItem src={p11} alt="GallaryIMAGE" />
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <GalleryItem src={p15} alt="GallaryIMAGE" />
                        <GalleryItem src={p13} alt="GallaryIMAGE" />
                        <GalleryItem src={p14} alt="GallaryIMAGE" />
                      </SwiperSlide>
                    </Swiper>
                  </SRLWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  gallary wrapper end =============== */}
      </>
    );
  }
}

export default Gallary;
