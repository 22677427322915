import React from "react";
import { Link } from "react-router-dom";

const GalleryItem = ({ src, alt }) => {
  return (
    <div className="gallary-item">
      <img src={src} alt={alt} />
      <Link to={src} className="gallary-item-overlay">
        <img src={src} alt={alt} />
      </Link>
    </div>
  );
};

export default GalleryItem;
