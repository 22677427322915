import React, { Component } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
//
import EventCard from "./component/eventCard";
import eventData from "./EventData";


class Events extends Component {
  componentDidMount(){
    new WOW.WOW().init()
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                
                  <h2 className="page-title">Nos événements</h2>
                  <ul className="page-switcher">
                    <li>
                      <Link
                        onClick={this.scrollTop}
                        to={`${process.env.PUBLIC_URL}/`}>
                        Acceuil <i className="bi bi-caret-right" />
                      </Link>
                    </li>
                    <li>Nos événements</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        {/* ===============  Event Area start  =============== */}
        <div className="event-area">
          <div className="container position-relative pt-110">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-head">
                  <h3>Nos événements</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="all-event-cards">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-event1" role="tabpanel" aria-labelledby="pills-tab1">
              <div className="row">
                {eventData.map((event, index) => (
                  <EventCard key={index} event={event} />
                ))}
              </div>
            </div>
            {/* Additional tab-pane content */}
          </div>
        </div>
            </div>
          </div>
        </div>
        {/* ===============  Event Area end  =============== */}
      </>
    );
  }
}

export default Events;
