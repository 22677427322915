import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
/*image light box*/
import SimpleReactLightbox from "simple-react-lightbox";
/*import wrapping layout*/
import Layout from "./components/App";
// layout default import from compnent
import MainLayout from "./components/layout/Main";
/*import all pages from components*/
import Error from "./components/pages/404/Error";
import About from "./components/pages/about/About";

import ContactPage from "./components/pages/contact/ContactPage";
import Events from "./components/pages/events/Events";
import Gallary from "./components/pages/Gallary/Gallary";


/*initialization All css*/
import "./index.css";

// default Warning Error hide
console.log = console.warn = console.error = () => {};


class Root extends React.Component {
  render() {
    return (
      <>
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path="/" component={MainLayout} />
           
            <Layout>
              <Route
                path={`${process.env.PUBLIC_URL}/about`}
                component={About}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/contact`}
                component={ContactPage}
              />

        
          

              {/* Event pages components */}
              <Route
                path={`${process.env.PUBLIC_URL}/event`}
                component={Events}
              />

          
              <Route
                path={`${process.env.PUBLIC_URL}/gallery`}
                component={Gallary}
              />
           
              <Route
                path={`${process.env.PUBLIC_URL}/error`}
                component={Error}
              />
            </Layout>
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
